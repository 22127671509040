// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
    apiUrl: 'https://service-test.orwi.app',
  //  apiUrl: 'https://192.168.1.125',
  // apiUrl: 'https://127.0.0.1', // https://gandalf.orwi.app original
 // apiUrl: 'https://gandalf.orwi.app', // https://gandalf.orwi.app original
  apiKey : "YWxpLTEyMzQ1MTEyNDU2NTQzMg",
  cdnService: "https://cdn.kerzz.com:4700",
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
